<template>
  <div ref="compRef" class="tabbed-gallery-container">
    <ScrollableContainer role="tablist" aria-labelledby="tablist-1">
      <CustomButton
        v-for="(link, i) in links"
        :id="getElementId(link.element_id, i)"
        :key="getElementId(link.element_id, i)"
        :aria-selected="i === pageIndex"
        :aria-controls="getElementTabpanel(link.element_id, i)"
        class="btn btn-secondary"
        type="button"
        role="tab"
        v-bind="link"
        @click-button="pageIndex = i"
      >
        <span class="focus">{{ link.link_text }}</span>
      </CustomButton>
    </ScrollableContainer>
    <TransitionGroup tag="div" name="tabs" class="tabbed-content-container">
      <div
        v-for="(tab, i) in mappedPages"
        v-show="i === pageIndex"
        :id="tab.id"
        :key="tab.id"
        class="tabbed-content"
        role="tabpanel"
        :tabindex="i === pageIndex ? 0 : -1"
        :aria-labelledby="tab.id"
      >
        <picture class="tabbed-image">
          <source
            :srcset="tab.image.srcset"
            media="(min-width: 768px)"
            :sizes="`(max-width: 1280px) 100vw, ${imageWidth}px`"
          />
          <img :srcset="tab.image.srcset" class="image" :alt="tab.image.alt || ''" />
        </picture>
        <div class="tabbed-products-container">
          <div class="tabbed-products-content">
            <h2 v-text="tab.title" />
            <p v-text="tab.description" />
          </div>
          <ScrollableContainer :is-component-section="true">
            <ProductCard
              v-for="(product, pi) in tab.productsConnection?.edges"
              :key="product?.product_offerings?.bc_primary_product?.product?.id"
              :product="product.node"
              :is-dynamic-section="true"
              render-mode="splash-page"
              :show_add_to_cart="true"
              :index="pi"
            />
          </ScrollableContainer>
        </div>
      </div>
    </TransitionGroup>
  </div>
</template>

<script setup lang="ts">
import { kebabCase } from 'lodash-es';
import { AssetCloudinary } from '~/types/contentstack';
import { useCloudinary } from '~/composables/cloudinary';
import { getAssetPublicId, getImageAlt } from '~/util/contentstack/csHelpers';

const cloudinary = useCloudinary();

const props = defineProps({
  pages: {
    type: Array as () => any[],
    required: true,
  },
});

function getAlt(item: AssetCloudinary[]): string | undefined {
  return getImageAlt(item);
}

const getElementId = (title: string, index: number) => {
  return `${kebabCase(title)}-${index}-tab`;
};

const getElementTabpanel = (title: string, index: number) => {
  return `${kebabCase(title)}-${index}-tabpanel`;
};

function resize(img: AssetCloudinary[], width: number, height: number): string {
  return cloudinary.bynderToCloudinaryResize(getAssetPublicId(img) as string, width, height);
}
function getSrcset(url: any, width: number, height: number) {
  return `${resize(url, width, height)} 1x, ${resize(url, width * 1.5, height * 1.5)} 2x, ${resize(
    url,
    width * 2,
    height * 2
  )} 3x`;
}

const pageIndex = ref(0);
// 670x739
const imageWidth = ref(670);
const imageHeight = ref(739);

const links = computed(() =>
  props.pages.map((p, i) => {
    return {
      link_text: p.title,
      element_id: getElementId(p.title, i),
    };
  })
);

const mappedPages = computed(() =>
  (props?.pages || []).map((p, i) => {
    return {
      id: getElementTabpanel(p.title, i),
      title: p.title,
      description: p.description,
      image: {
        url: cloudinary.generateImageUrl(p?.image?.[0]?.public_id),
        srcset: getSrcset(p.image, imageWidth.value, imageHeight.value),
        alt: getAlt(p.image),
      },
      productsConnection: p.productsConnection,
    };
  })
);
</script>

<style lang="scss" scoped>
.tabbed-gallery-container {
  width: 100%;
}
.image-container {
  width: 50vw;
}
div[role='tablist'] {
  width: 100%;
  padding-bottom: #{local-functions.rem-calc(20)};
  :deep(.scroll-container) {
    justify-content: flex-start;
    gap: 0;
  }
  :deep(button) {
    white-space: nowrap;
    margin-right: 0.35em;
  }
}
div[role='tabpanel'] {
  display: flex;
  justify-content: space-between;
  width: 100%;
  picture,
  .tabbed-products-container {
    width: 100%;
    @include local-mixins.desktop {
      width: 50%;
    }
  }
  .tabbed-image {
    display: none;
    @include local-mixins.desktop {
      display: block;
      width: calc(50% - #{local-functions.rem-calc(40)});
    }
  }
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
.tabbed-products-content {
  display: none;
  @include local-mixins.desktop {
    display: block;
  }
}
.tabbed-content-container {
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
}
.tabs-enter-from,
.tabs-leave-to {
  transition: opacity 0.25s ease-in-out;
  opacity: 0;
}

.tabs-leave-from,
.tabs-enter-to {
  transition: opacity 0.25s ease-in-out;
  opacity: 1;
}
</style>
